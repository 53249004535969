import type { FormEvent, FormHTMLAttributes, FunctionComponent } from "react";
import Button from "~/components/button";
import { SelectField, TextareaField, TextField } from "~/components/fields";

type Props = FormHTMLAttributes<HTMLFormElement>;

console.log(process.env);

const ContactForm: FunctionComponent<Props> = (props) => {
  async function submit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const response = await fetch(import.meta.env.LAMBDA_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    if (data.message) {
      console.log("success", data.message);
    }
  }

  return (
    <form
      {...props}
      onSubmit={submit}
      action="#"
      className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2"
    >
      <TextField
        label="First name"
        name="firstName"
        type="text"
        autoComplete="given-name"
        required
      />
      <TextField
        label="Last name"
        name="lastName"
        type="text"
        autoComplete="family-name"
        required
      />
      <TextField
        className="col-span-full"
        label="Email address"
        name="email"
        type="email"
        autoComplete="email"
        required
      />

      <TextareaField
        className="col-span-full"
        label="Message"
        name="message"
        required
        rows={5}
      />

      <SelectField
        className="col-span-full"
        label="How did you hear about us?"
        name="referral_source"
      >
        <option>Other</option>
        <option>Search engine result</option>
        <option>Social media</option>
        <option>Email</option>
        <option>Word of mouth</option>
        <option>Event or trade show</option>
      </SelectField>
      <div className="col-span-full">
        <Button type="submit" variant="solid" color="blue" className="w-full">
          <span>Send</span>
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
